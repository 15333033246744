import React from 'react'

const Hero = () => {
  return (
    <div className="background-salon-hero-1 hero-image">
      <div className="p-5">
        <h1 className="hero-text">
          Welcome to the <br />
          <b>Little Hair Lounge</b>
        </h1>
      </div>
    </div>
  )
}

export default Hero
